/*#navbar .navbar{
    background-color: white;
    box-shadow: rgb(0, 0, 0, 0.2) 0px 2px 2px;
}*/

/*.fixed-top .scroll-navbar{
    background-image: url('https://temp.assec.pt/repo/turistrela/estanciaski/static/assets/img/banner_topo.png');
    position: relative;
    height: auto;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}*/

/*.sticky-top #navbar {

    *background: #e9ecef;
    background: var(--white);
}*/

@media (max-width: 992px) {
    .navbar-collapse{
        background-color: var(--white);
    }
}

/*.navbar .current_top {
    color: var(--orange);
}*/

/*.navbar-expand-lg .navbar-nav .nav-link{
    margin-left: 25px;
    margin-right: 25px;
}*/

#navbar .navbar-expand-lg .navbar-nav .nav-item a{
    color: var(--black);
    font-weight: 700;
    /*cursor: pointer;
    padding-bottom: 0px;
    border-bottom: 2px solid transparent;*/
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
}


#navbar .navbar.navbar-expand-lg .navbar-nav .nav-item:last-child a {
    padding-right: 0 !important;

}

#navbar .scroll-navbar .navbar-expand-lg .navbar-nav .nav-item a,
#navbar .scroll-navbar.navbar-expand-lg .navbar-nav .nav-item a { 
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
}

#navbar .navbar-expand-lg .navbar-nav .nav-item a:hover{
    /*border-bottom: 2px solid var(--blue-light);*/
    color: var(--blue-light);
}

#navbar .navbar-expand-lg .navbar-nav .nav-item a.current_top{ 
    color: var(--blue-light);
}

#navbar .navbar-expand-lg .dropdown-menu {
    border: none;
    -webkit-box-shadow: 0px 2px 10px rgba(0, 0, 0, .175);
    box-shadow: 0px 2px 10px rgba(0, 0, 0, .175);
}


#navbar .navbar-expand-lg .dropdown-menu > a.dropdown-item {
    color: var(--body);
    font-weight: 700;
    background-color: unset;
}


#navbar .navbar-expand-lg .dropdown-menu a:hover,
#navbar .navbar-expand-lg .dropdown-menu a:focus {
    color: var(--blue-light);
}

.button_no_border{    
    border: 0px;
    background:transparent;
    cursor: pointer;
}

.button_no_border:focus{    
    border: 0px;
    outline: 0px;
}

.navbar-light .navbar-toggler{
    border: 0;
}

.navbar-light .navbar-toggler:focus{
    outline: 0;
}

/*#navbar a.navbar-brand {
    padding-bottom: 0px;
    border-bottom: 0px solid transparent;
}*/

/*#navbar a.navbar-brand:hover{
    padding-bottom: 0px;
    border-bottom: 0px solid transparent;
}*/

.navbar-brand {
    z-index: 21;
}


.svg-logo{
    /*height: 120px;*/
    height: 100px;
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
}

.scroll-navbar .svg-logo{
    /*height: 90px;*/
    height: 60px;
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
}


.top-bar {
    /*top: 0;*/
    height: 31px;
    transform: translateY(0);
    background-color: #fafafa;
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
}


.scroll-navbar .top-bar {
    /*position: absolute;*/
    /*top: -40px;*/
    transform: translateY(-50px);
    height: 0;
    background-color: transparent;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    -moz-transition: all 0.7s ease-in-out;
    -o-transition: all 0.7s ease-in-out;
    -ms-transition: all 0.7s ease-in-out;
    transition: all 0.7s ease-in-out;
}

.top-bar button:hover,
.top-bar button.current_lang {
    color: var(--orange);
}


@media (max-width: 767px) { 
    .top-bar-mobile {
        height: 70px !important;
        -moz-transition: all 0.7s ease-in-out;
        -o-transition: all 0.7s ease-in-out;
        -ms-transition: all 0.7s ease-in-out;
        transition: all 0.7s ease-in-out;
    }

    .scroll-navbar .top-bar-mobile {
        transform: translateY(-100px) !important;
        height: 0 !important;
        -moz-transition: all 0.7s ease-in-out;
        -o-transition: all 0.7s ease-in-out;
        -ms-transition: all 0.7s ease-in-out;
        transition: all 0.7s ease-in-out;
    }
}

/*#small-logo{
    display: none;
}*/

.svg-logo-small{
    height: 40px;
}

.nav-effect{
    -webkit-transition: background 1.5s;
    -moz-transition: background 1.5s;
    -ms-transition: background 1.5s;
    -o-transition: background 1.5s;
    transition: background 1.5s;
    background: var(--white);
}

.sticky-top .nav-effect{
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 2px;
}

.h-0-transition{
    height: 0;
    -moz-transition: all 2s ease-in-out;
    -o-transition: all 2s ease-in-out;
    -ms-transition: all 2s ease-in-out;
    transition: all 2s ease-in-out;
}

.dropdown-toggle:after { content: none }

@media (max-width: 767px) {
    .svg-logo{
        height: 70px;
        -moz-transition: all 0.7s ease-in-out;
        -o-transition: all 0.7s ease-in-out;
        -ms-transition: all 0.7s ease-in-out;
        transition: all 0.7s ease-in-out;
    }
    
    .scroll-navbar .svg-logo{
        height: 50px;
        -moz-transition: all 0.7s ease-in-out;
        -o-transition: all 0.7s ease-in-out;
        -ms-transition: all 0.7s ease-in-out;
        transition: all 0.7s ease-in-out;
    }
}

@media only screen and (min-width : 992px) {

    /*open dropdown-menu on hover (>992px)*/
    #navbar .navbar-expand-lg .dropdown:hover > .dropdown-menu {
        display: block;
    }

}

/* WITH FIXED SIDE NAVIGATION MENU */

    .navbar-toggler {
        /*background-color:#FFF;
        padding: 0.75rem 0.75rem;*/
        z-index: 21;
    }

    #sideNavigationMenu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        /*background: rgba(37, 44, 48, 1);*/
        background: var(--blue-dark);
        position: fixed;
        z-index: 20;
        top: 0px;
        right: 0;
        height: 100%;
        width: 100%;
        padding: 120px 40px 60px 40px;
        overflow-y: auto;
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
        transform: translateX(100%);
        -webkit-transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        -moz-transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        -o-transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }
    #sideNavigationMenu.show {
        -webkit-transform: translateX(0px);
        -moz-transform: translateX(0px);
        -ms-transform: translateX(0px);
        -o-transform: translateX(0px);
        transform: translateX(0px);
    }

    /*#sideNavigationMenu ul.navbar-nav {
        list-style-type: none;
    }*/
    #sideNavigationMenu ul.navbar-nav li {
        -webkit-transform: translateX(40px);
        -moz-transform: translateX(40px);
        -ms-transform: translateX(40px);
        -o-transform: translateX(40px);
        transform: translateX(40px);
        opacity: 0;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        /*float: none;*/
        color: #fff
    }

    #sideNavigationMenu.show ul.navbar-nav li {
        -webkit-transform: translateX(0px);
        -moz-transform: translateX(0px);
        -ms-transform: translateX(0px);
        -o-transform: translateX(0px);
        transform: translateX(0px);
        opacity: 1;
    }

    #sideNavigationMenu ul.navbar-nav > li.nav-item > a{
        font-family: 'Outfit', sans-serif;
        color: var(--white);
        font-size: 30px;
        font-weight: 700;
        text-align: center;
    }

    #sideNavigationMenu .dropdown-menu {
        border: none;
        background-color: transparent;
        /*-webkit-box-shadow: 0px 2px 10px rgba(0, 0, 0, .175);
		box-shadow: 0px 2px 10px rgba(0, 0, 0, .175);*/
    }

    #sideNavigationMenu .nav-item.dropdown .dropdown-menu > a.dropdown-item {
        color: var(--white);
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        background-color: unset;

        /*-webkit-transform: translateX(40px);
        -moz-transform: translateX(40px);
        -ms-transform: translateX(40px);
        -o-transform: translateX(40px);
        transform: translateX(40px);
        opacity: 0;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;*/
    }

    /*#sideNavigationMenu .nav-item.dropdown .dropdown-menu.show > a.dropdown-item {

        -webkit-transform: translateX(0px);
        -moz-transform: translateX(0px);
        -ms-transform: translateX(0px);
        -o-transform: translateX(0px);
        transform: translateX(0px);
        opacity: 1;
    }*/

    #sideNavigationMenu .nav-item.dropdown .dropdown-menu > a.dropdown-item:hover,
    #sideNavigationMenu .nav-item.dropdown .dropdown-menu > a.dropdown-item:focus,
    #sideNavigationMenu .nav-item.dropdown .dropdown-menu > a.dropdown-item.current_top {
        color: var(--blue-light);
    }


    /*#sideNavigationMenu .dropdown-menu {
		background-color: var(--white);
		border: none;
		border-radius: 5px;
		padding: 10px 0;
		min-width: max-content;
		border-top: 1px solid #E1E1E1;
		-webkit-box-shadow: 0px 2px 10px rgba(0, 0, 0, .175);
		box-shadow: 0px 2px 10px rgba(0, 0, 0, .175);
	}*/

	/*#sideNavigationMenu .dropdown-menu a {
		padding: 0 10px;
		clear: both;
		line-height: 2;
		white-space: nowrap;
		outline: none !important;
		color: var(--black);
		font-size: 0.875rem;
		font-weight: 700;
		line-height: 24px;
	}*/

	/*#sideNavigationMenu .dropdown-menu a:hover,
	#sideNavigationMenu .dropdown-menu a:focus {
		color: var(--blue-light);
	}*/

    #sideNavigationMenu ul.navbar-nav .nav-item.dropdown.show .nav-link { 
        color: var(--blue-light);
    }

    #sideNavigationMenu ul.navbar-nav .nav-item a:hover,
    #sideNavigationMenu ul.navbar-nav .nav-item a.current_top { 
        color: var(--blue-light);
    }

    #sideNavigationMenu.show ul.navbar-nav li:nth-child(1) {
        transition-delay: 0.6s;
    }
    #sideNavigationMenu.show ul.navbar-nav li:nth-child(2) {
        transition-delay: 0.8s;
    }
    #sideNavigationMenu.show ul.navbar-nav li:nth-child(3) {
        transition-delay: 1s;
    }
    #sideNavigationMenu.show ul.navbar-nav li:nth-child(4) {
        transition-delay: 1.2s;
    }
    #sideNavigationMenu.show ul.navbar-nav li:nth-child(5) {
        transition-delay: 1.4s;
    }
    #sideNavigationMenu.show ul.navbar-nav li:nth-child(6) {
        transition-delay: 1.6s;
    }
    #sideNavigationMenu.show ul.navbar-nav li:nth-child(7) {
        transition-delay: 1.8s;
    }
    #sideNavigationMenu.show ul.navbar-nav li:nth-child(8) {
        transition-delay: 2s;
    }
    #sideNavigationMenu.show ul.navbar-nav li:nth-child(9) {
        transition-delay: 2.2s;
    }
    #sideNavigationMenu.show ul.navbar-nav li:nth-child(10) {
        transition-delay: 2.4s;
    }
    #sideNavigationMenu.show ul.navbar-nav li:nth-child(11) {
        transition-delay: 2.6s;
    }

    #sideNavigationMenu.show ul.navbar-nav.social-media li:nth-child(1) {
        transition-delay: 1.6s;
    }

    /*#sideNavigationMenu.show  .nav-item.dropdown .dropdown-menu.show .dropdown-item:nth-child(1){ 
        transition-delay: 1.6s;
    }
    #sideNavigationMenu.show  .nav-item.dropdown .dropdown-menu.show .dropdown-item:nth-child(2){ 
        transition-delay: 1.8s;
    }*/

    #sideNavigationMenu.show ul.navbar-nav.social-media li img:hover {
        filter: drop-shadow(0px 0px 20px var(--blue-light));
    }

    #sideNavigationMenu.show .about,
    #sideNavigationMenu.show .social,
    #sideNavigationMenu.show ul.sub {
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px);
        opacity: 1;
        transition-delay: .85s;
    }

    .animated-icon3 {
        width: 30px;
        height: 22px;
        position: relative;
        margin: 5px;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;
    }

    .animated-icon3 span {
        display: block;
        background: var(--black);
        position: absolute;
        height: 3px;
        width: 100%;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
    }

    button[aria-expanded="true"] .animated-icon3 span {
        background: var(--white);
    }

    .animated-icon3 span:nth-child(1) {
        top: 0px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }

    .animated-icon3 span:nth-child(2) {
        top: 10px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }

    .animated-icon3 span:nth-child(3) {
        top: 20px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
    }

    button[aria-expanded="true"] .animated-icon3 span:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 0px;
        left: 8px;
    }

    button[aria-expanded="true"] .animated-icon3 span:nth-child(2) {
        width: 0%;
        opacity: 0;
    }

    button[aria-expanded="true"] .animated-icon3 span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 21px;
        left: 8px;
    }

    @media (max-width: 992px) {
        .navbar-expand-lg.navbar-collapse{
            display: none !important;
        }
        #sideNavigationMenu.show ul.navbar-nav.social-media li:nth-child(1) {
            transition-delay: 2.8s;
        }
    }

    @media (max-width: 767px) { 
        #sideNavigationMenu { 
            display: block;
            padding: 150px 40px 60px 40px;
        }
        #sideNavigationMenu ul.navbar-nav > li.nav-item > a {
            font-size: 22px;
        }
    }


    
/* WITH FIXED SIDE NAVIGATION MENU */