#aula .div-schedueles {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    border: 1px solid #f6f7f9;
    border-radius: 8px;
}
#aula .schedueles-choose {
    background-color: #EEF4FD;
    border-radius: 0 8px 8px 0;
}
#aula .calendar-container {
    padding: 25px;
}

#aula .react-calendar { 
    width: 400px;
    max-width: 100%;
    background-color: #fff;
    color: #222;
    border-radius: 8px;
    /* box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); */
    font-family: 'Poppins', sans-serif;
    /* border: 1px solid #f6f7f9; */
    border: none;
    line-height: 1.125em;
}

#aula .react-calendar__navigation button {
    color: var(--blue-dark);
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
}

.react-calendar__month-view__days__day--weekend {
    color: rgba(16, 16, 16, 0.3);
}

#aula .react-calendar__tile {
    background-color: #e6e6e6;
    border: 4px solid #fff !important;
    border-radius: 15px;
}
#aula .react-calendar__tile:disabled {
    background-color: #ffffff;
}
#aula .react-calendar__tile--now {
    background-color: #e6e6e6 !important;
    position: relative;
}
#aula .react-calendar__tile--now::before {
    content: "\F309";
    font-family: bootstrap-icons !important;
    position: absolute;
    top: 0;
    right: 5px;
    font-size: 15px;
}
#aula .react-calendar__tile--active {
    background-color: var(--orange) !important;
    color: white;
    border-radius: 30px;
}

#aula .btn-scheduele {
    font-family: 'Outfit', sans-serif;
    font-size: 14px;
    font-weight: 700;
    background-color: var(--white);
    box-shadow: 0 2px 2px rgb(0 0 0 / 20%);
    /* border: 1px solid var(--blue-light); */
    color: var(--black);
    border-radius: 30px;
}
#aula .btn-scheduele.active {
    background-color: var(--blue-dark);
    border: 1px solid var(--blue-dark);
    color: #FFFFFF;
}

#aula .react-calendar__month-view__weekdays__weekday {
    text-transform: capitalize;
}

abbr[data-original-title], abbr[title] {
    -webkit-text-decoration: none;
    text-decoration: none;
}