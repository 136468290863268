#pack table {
    width: 100%;
}
#pack table thead tr {
    background-color: var(--gray-light);
}
#pack table thead tr td,
#pack table tbody tr td {
    width: 25%;
}
#pack table thead tr td:first-child,
#pack table tbody tr td:first-child {
    width: 50%;
}

#pack .quantity-picker {
    border-radius: 0px !important;
}
#pack .quantity-modifier {
    width: 2rem !important;
    background: #ffffff !important;
}
#pack .quantity-display {
    width: 2rem !important;
    font-size: 1rem;
}

#pack .expand {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s 0.1s ease-in-out;
    transition: all 0.3s ease-in-out;
}

#pack .border-bottom {
    cursor: pointer;
    border-bottom: 1px solid #dee2e6;
}