#footer {
	background-color: var(--blue-dark);
	margin-top: 170px;
    /*width: 100%;
    bottom: 0;
	position: absolute;*/
	
}
footer a {
	color: var(--gray);
}

footer h5 {
	font-weight: 600;
	color: var(--blue-light);
}
footer h6 {
	font-weight: 400;
    font-size: 0.875rem;
}

/*footer span, footer a {
	font-size: 0.75rem;
	display: flex;
	align-items: flex-start;
}*/

footer span.logosfooter {
	display: flex;
	align-items: flex-start;
	justify-content: space-evenly;
}
footer .copyright span {
    display: inherit;
	font-size: 12px;
}

.current_bottom{
    text-decoration: underline;
}

footer .social-media img:hover {
	filter: drop-shadow(0px 0px 20px var(--blue-light));
}

img.light-logo.svg-logo.img-fluid {
	filter: brightness(0) invert(1);
}