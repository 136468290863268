/* TIMELINE */
.timeline {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timeline li {
  height: 90px;
  transition: all 200ms ease-in;
}
.timeline .timestamp {
  margin-bottom: 20px;
  padding: 0px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 100;
}
.timeline .status {
  padding: 25px 40px 0px;
  width: 250px;
  display: flex;
  justify-content: center;
  border-top: 1px solid #606060;
  position: relative;
  transition: all 200ms ease-in;
}
.timeline li div.on-course {
  width: 250px;
  display: flex;
  justify-content: center;
  position: relative;
  transition: all 200ms ease-in;
}
.timeline li .status:before {
  content: '';
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 25px;
  border: 1px solid #FFFFFF;
  position: absolute;
  top: -13px;
  left: 112px;
  transition: all 200ms ease-in;
}
.timeline li .status:after {
  content: "";
  position: absolute;
  top: -8px;
  width: 15px;
  height: 15px;
  border-radius: 8px;
  background: #f6f7f9;
}
.timeline li.completed .status:before {
  background-color: #102534;
  border: none;
  transition: all 200ms ease-in;
}
.timeline li.completed .status:after {
  content: "";
  position: absolute;
  top: -6px;
  width: 5px;
  height: 10px;
  background: transparent;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.timeline li.error .status:before {
  background-color: #dc7866;
  border: none;
  transition: all 200ms ease-in;
}
.timeline li.error .status:after {
  content: "\d7";
  position: absolute;
  top: -18px;
  margin-left: -10px;
  width: 5px;
  height: 10px;
  background: transparent;
  color: #FFF;
  font-size: 23px;
}
.timeline li.on-course .status:before {
  transition: all 200ms ease-in;
  border: 1px solid #e2970e;
}
.timeline li.on-course .status:after {
  content: "";
  background: #e2970e;
  border: none;
}
.timeline li p,
.timeline li a {  
  color: #606060 !important;
  font-weight: 400 !important;
  margin: 0 !important;
  text-align: center;
}


.table-responsive table tr:first-child td {
  border-top: 0px;
}

#cart .timeline {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
#cart .timeline .step {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}
#cart .timeline .step .dot {
  height: 30px;
  width: 30px;
  background-color: #dee2e6;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}
#cart .timeline .step .dot.completed {
  color: green;
  background-color: #dee2e6;
}
#cart .timeline .step .dot.active {
  color: #FFF;
  background-color: green;
}
#cart .timeline .step .dot i {
  font-size: 20px;
  font-weight: 800;
  color: green;
  text-align: center;
  vertical-align: middle;
}
#cart .timeline .step .dot span {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  vertical-align: sub;
}
#cart .timeline .step .label.active {
  font-weight: 600;
}
#cart .timeline .step .line {
  width: 100px;
  border-top: 3px solid #dee2e6;
}
#cart .timeline .step .line.completed {
  border-top: 3px solid green;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 700px) {
  .timeline,
  #cart .timeline {
    display: block;
  }
  .timeline li{
    transition: all 200ms ease-in;
    display: flex;
    width: inherit;
  }
  .timeline .timestamp {
    width: 100px;
  }
  .timeline .status:before {
      left: -8%;
      top: 30%;
      transition: all 200ms ease-in ;
  }
}