#acessos iframe {
    height: 500px;
    width: 500px;

}

@media (max-width: 576px){
    #acessos iframe {
        height: 360px;
    
    }
}