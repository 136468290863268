#tracks {
    font-size: 12px;
}

#tracks hr {
    border-top: 1px solid var(--blue-light);
}

#tracks .card {
    border-radius: 0;
    flex-direction: row;
    /*justify-content: space-evenly;*/
}

#tracks .dificulty,
#tracks .state {
    display: flex;
    justify-content: center;
    align-items: center;
    width : 50px;
}

#tracks .info {
    word-break: break-word;
}

#tracks .state {
    flex: auto;
    justify-content: right;
}

#tracks .pista-muito_facil{
    color: #378C6D;
}
#tracks .pista-facil{
    color: #0888C1;
}
#tracks .pista-dificil{
    color: #A00D0D;
}
#tracks .pista-muito_dificil{
    color: black;
}
#tracks .pista-not_applicable{
    color: lightgray;
}

/* 2 colunas e em cada coluna é colocado metade das pistas ordenadas  */
/* retira bordem-bottom em todos os cards menos no último */
#tracks .col-md-6 .pista:not(:last-child) .card {
    border-bottom: unset;
}

@media screen and (max-width: 767px) {

    /* pistas e colunas com ordem normal */
    /* retira bordem-bottom em todos os cards menos no último */
    #tracks .col-md-6:not(:last-child) .card {
        border-bottom: unset;
    }

}

@media screen and (min-width: 768px) {
    #tracks .col-md-6:nth-child(2n) .card {
        border-left: unset;
    }
    
    /* pistas e colunas com ordem normal */
    /* retira bordem-bottom em todos os cards menos nos dois últimos */
    #tracks .col-md-6:not(:nth-last-child(-n+2)) .card {
        border-bottom: unset;
    }

}

/*#tracks .col-sm-6:not(:last-child) .card {
    border-bottom: unset;
}*/

/*#tracks .col-sm-6:nth-child(1) .card,
#tracks .col-sm-6:nth-child(2) .card {
    border-bottom: unset;
}*/


.div-mapa-svg svg {
    width: 100% !important;
    height: auto !important;
}

.div-mapa-svg #Bolas_pistas g:hover {
    filter: drop-shadow(2px 4px 6px gray);
    cursor: pointer;
}

.div-mapa-svg #Fundo_1_ g.meios-mecanicos:hover,
.div-mapa-svg #Fundo_1_ polygon.meios-mecanicos:hover,
.div-mapa-svg #Fundo_1_ g.geral:hover,
.div-mapa-svg #Fundo_1_ circle.geral:hover {
    /*filter: drop-shadow(2px 4px 6px black);*/
    cursor: pointer;
}