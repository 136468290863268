
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@700&family=Poppins:wght@400;700&display=swap');

/* font-family: 'Outfit', sans-serif;
font-family: 'Poppins', sans-serif; */

:root {
    --white: #FFFFFF;
    --blue: #3f5099;
    --blue-light: #83a1b2;
    --blue-dark: #102534;
    --orange: #f96332;
    --red: #e4294e;
    --green: #6ca66c;
    --yellow: #ffaa00;
    --gray-dark: #141414;
    --gray: #afafaf;
    --gray-light: #eef4fd;
    --body: #444444;
    --black: #000000;
}
/*.margemBody{
}*/
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.25px;
  color: var(--body);
}

*:focus {
    outline: none !important;
    box-shadow: 0 0 0 0 transparent !important;
   }

::selection {
    background: var(--blue-light);
    color: #FFF;
}
  
/*p {
    margin: 0px 0px 10px 0px;
    line-height: 1.75em;
}*/

a {
	color: var(--blue-light);
    word-break: break-word;
	transition: all .2s;
}
a:hover {
    color: var(--gray);
    outline: none;
}

a.color{
    color: var(--blue);
}
a.color-red{
    color: var(--red);
}
a.color-orange{
    color: var(--orange);
}

/* MARGINS && PADDINGS */
    .mt-100 {
        margin-top: 100px;
    }
    .py-100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }
    #info.py-100 {
        padding-top: 85px;
        padding-bottom: 85px;
    }
/* end MARGINS && PADDINGS */

/* JUSTIFY-CONTENT */

.justify-content-evenly {
    justify-content: space-evenly;
}

/* END JUSTIFY-CONTENT */

/* BORDER-RADIUS */

    .rounded-20 {
        border-radius: 20px;
    }

/* END BORDER-RADIUS */

.btn{
    white-space: normal;
    padding: .375rem 1.125rem;
    font-size: 0.875rem;
}
.btn-primary {
    /*font-family: 'Outfit', sans-serif;
    font-size: 14px;
    font-weight: 700;*/
    background-color: var(--blue-dark);
    border: 1px solid var(--blue-dark);
    color: var(--white);
    border-radius: 30px;
}
.btn-primary:hover, .btn-primary:active,
.btn-primary.active, .open > .dropdown-toggle.btn-primary,
.btn-primary:focus, .btn-primary.focus{
    background-color: var(--orange) !important;
    border-color: var(--orange) !important;
    outline: none;
    color: var(--white);
    box-shadow: none;
}
.btn-primary.disabled, .btn-primary:disabled{
    background-color: var(--blue-dark);
    border-color: var(--blue-dark);
}
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0rem rgba(16, 37, 52,.5);
}
.btn-secondary {
    /*font-family: 'Outfit', sans-serif;
    font-size: 14px;
    font-weight: 700;*/
    background-color: var(--blue-light);
    border: 1px solid var(--blue-light);
    color: var(--white);
    border-radius: 30px;
}
.btn-secondary:hover, .btn-secondary:active,
.btn-secondary.active, .open > .dropdown-toggle.btn-secondary,
.btn-secondary:focus, .btn-secondary.focus{
    background-color: var(--orange) !important;
    border-color: var(--orange) !important;
    outline: none;
    color: var(--white);
    box-shadow: none;
}
.btn-secondary.disabled, .btn-secondary:disabled{
    background-color: var(--blue-light);
    border-color: var(--blue-light);
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0rem rgba(16, 37, 52,.5);
}
#info .btn-primary {
    background-color: var(--white);
    font-weight: 700;
    border: 1px solid var(--white);
    color: var(--blue);
    border-radius: 30px;
}
#info .btn-primary:hover, #info .btn-primary:active,
#info .btn-primary.active, #info .open > .dropdown-toggle.btn-primary,
#info .btn-primary:focus, #info .btn-primary.focus{
    background-color: var(--orange);
    border-color: var(--orange);
    outline: none;
    color: var(--white);
}

.btn-link:hover{
    color: var(--blue);
    text-decoration: underline;
}

button.btn-link:hover,
button.btn-link:focus{
    text-decoration: none;
}

.txt-btn {
    color: var(--black);
    -webkit-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}
.txt-btn:hover {
    color: var(--blue-light) !important;
    text-decoration: underline;
}

.txt-btn > .fa-long-arrow-alt-right,
.txt-btn > .fa-long-arrow-alt-left {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 75%;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
}
.txt-btn:hover > .fa-long-arrow-alt-right {
    margin-left: 10px;
}
.txt-btn:hover > .fa-long-arrow-alt-left«t {
    margin-right: 10px;
}

.accordion .btn{
    background-color: transparent;
}

/*.accordion button.collapsed:before{
    font-family: "Font Awesome 5 Free";
    font-size: 0.7rem;
    font-weight: 900; 
    content: "\f067";
    float: left;
    margin-right: 15px;
    color: white;
    border-radius: 50%;
    padding: 2.5px 6px;
}
.accordion button:before{
    font-family: "Font Awesome 5 Free";
    font-size: 0.7rem;
    font-weight: 900; 
    content: "\f068";
    float: left;
    margin-right: 15px;
    color: white;
    border-radius: 50%;
    padding: 2.5px 6px;
}
.accordion button.btn-link.collapsed:before{
    background-color: var(--blue);
}
.accordion button.btn-link:before{
    background-color: var(--blue);
}*/
.accordion button.btn-link-red.collapsed:before{
    background-color: var(--red);
}
.accordion button.btn-link-red:before{
    background-color: var(--red);
}
.accordion button.btn-link-orange.collapsed:before{
    background-color: var(--orange);
}
.accordion button.btn-link-orange:before{
    background-color: var(--orange);
}

.titulo-primario, .titulo-secundario {
    font-family: 'Outfit', sans-serif;
    color: var(--blue-dark);
    font-size: 30px;
    font-weight: 700;
    line-height: 1.2;
}
.titulo-secundario{
    font-size: 18px;
}
.subtitulo-primario, .subtitulo-secundario{
    font-family: 'Outfit', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: var(--blue-light);
    line-height: 1.2;
}
.subtitulo-secundario{
    /*font-weight: 400;*/
    color: var(--blue-dark);
}

.vertical-title-left,
.vertical-title-right {
    display: none;
}

blockquote {
    border-left: 5px solid var(--red);
    font-size: inherit;
    background-color: #f6f6f6;
    margin: 20px 0 20px 20px;
    padding: 1rem 1rem 0.5rem;
}
.limit-5-lines{
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.limit-10-lines{
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.column-count-2{
    column-count: 2;
}
@media (max-width: 576px) {
    .column-count-2{
        column-count: 1;
    }
}

.bg-color-gray-light{
    background-color: var(--gray-light);
}
.bg-color-gray{
    background-color: var(--gray);
}
.bg-color-red{
    background-color: var(--red);
}
.bg-color-orange{
    background-color: var(--orange);
}
.bg-color-blue{
    background-color: var(--blue);
}
.bg-color-body{
    background-color: var(--body);
}
.bg-color-white{
    background-color: var(--white);
}

.parallax{
    position: relative;
    height: auto;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
}

.background-image{
    position: relative;
    height: 250px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.overlay-img {
    background: rgba(211, 215, 221, 0.6);
    width: 100%;
    height: 100%;
}

.loader {
    margin: 0;
    position: fixed;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
}

/* INFO RESORT */

    #info_resort_fixed {

        position: fixed;
        display: flex;
        flex-direction: column;
        right: 1px;
        top: 30%;
        z-index: 999;
        
    }

    #info_resort_fixed a{
        word-break: normal;
    }

    #info_resort_fixed .round-card {
        /*display: block;
        align-items: center;
        justify-content: center;*/
        /*flex-direction: column;*/
        background-color: var(--gray-light);
        /*border-radius: 50%;*/
        width: 90px;
        /*height: 100px;*/
        overflow: hidden;
        float: right;
        margin-bottom: 10px;
        border: 10px solid var(--white);
        border-radius: 0.25rem;
        box-shadow: 0px 0px 0px 2px var(--gray-light);
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }

    #info_resort_fixed .round-card img {
        height: 40px;
        margin: 15px;
    }

    #info_resort_fixed .round-card .info-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: fit-content;
    }

    #info_resort_fixed .round-card .info-content .titulo-secundario {
        font-size: 16px;
    }

    #info_resort_fixed .round-card .info-content .numbers {
        font-weight: 700;
        font-size: 18px;
        color: var(--orange);
    }

    #info_resort_fixed .round-card.btn_01:hover,
    #info_resort_fixed .round-card.btn_02:hover,
    #info_resort_fixed .round-card.btn_03:hover,
    #info_resort_fixed .round-card.btn_04:hover{
        width: 250px !important;

        box-shadow: 0 3px 6px rgb(0 0 0 / 6%), 0 3px 6px rgb(0 0 0 / 8%);

        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
    }
    #info_resort .round-card {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: var(--gray-light);
        border-radius: 50%;
        width: 220px;
        height: 220px;
        border: 10px solid var(--white);
        box-shadow: 0px 0px 0px 2px var(--gray-light);
    }

    #info_resort .round-card .numbers {
        font-weight: 700;
        font-size: 22px;
        color: var(--orange);
    }

    @media (max-width: 767px) {

        #info_resort_fixed {
            display: none;
        }

        #info_resort .round-card {
            margin-bottom: 15px;
        }
    }
/* INFO RESORT */


/* PROGRAMAS/PRODUCTS */

#programas .product-content {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.product-content .price{
    font-family: 'Outfit', sans-serif;
    font-weight: 700;
    font-size: 18px;
}

.product-content .product-name{
    font-family: 'Outfit', sans-serif;
    font-weight: 700;
    font-size: 16px;
}

.product-content .product-group{
    font-family: 'Outfit', sans-serif;
    font-weight: 700;
    font-size: 16px;
    color: var(--blue-light)
}

/* PROGRAMAS/PRODUCTS */

/* DESTAQUES */
#destaque_1 .mp-mask,
#destaque_2 .mp-mask{
    margin: 75px auto;
    padding-top: 100px;
    padding-bottom: 100px;
}
#destaque_1::after
/*.slideshow::after*/
/*#destaque_2::after*/ {
    content: '';
    display: block;
    background-image: url('https://cdn.assec.app/repo/turistrela/estanciaski/static/assets/img/banner_snow.png');
    width: 100%;
    height: 170px;
    position: absolute;
    left: 0;
    bottom: -40px;
    z-index: 2;
}
#destaque_1::before,
#destaque_2::before {
    content: '';
    display: block;
    background-image: url('https://cdn.assec.app/repo/turistrela/estanciaski/static/assets/img/banner_snow.png');
    width: 100%;
    height: 170px;
    position: absolute;
    left: 0;
    top: -40px;
    z-index: 2;
    transform: rotate(180deg);
}

#footer:before {
    content: '';
    display: block;
    background-image: url('https://cdn.assec.app/repo/turistrela/estanciaski/static/assets/img/banner_snow_footer.png');
    width: 100%;
    height: 170px;
    position: absolute;
    left: 0;
    /*top: -75px;*/
    /*transform: rotate(180deg);*/
    z-index: 2;
    margin-top: -168px;
}
/* end DESTAQUES */

/* TESTEMUNHOS/OPINIÃO */

    #opinion .testimonial_text {
        font-size: 14px;
        font-weight: 500;
        color: var(--body);

    }

/* END TESTEMUNHOS/OPINIÃO */


/* SEARCH */
    .limit-lines {
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
/* end SEARCH */


/* PAGINATION */
    .pagination {
        margin: 15px auto;
        display: flex;
        list-style: none;
        outline: none;
    }
    .pagination > .active > a{
        background-color: var(--body);
        border-color: var(--body);
        color: #fff;
    }
    .pagination > li > a{
        /*border: 1px solid var(--body);*/
        padding: 5px 10px;
        margin: auto 5px;
        outline: none;
        cursor: pointer;
    }
    .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
        background-color: var(--blue);
        border-color: var(--blue);
        outline: none;
    }
    .pagination > li > a, .pagination > li > span{
        color: var(--body);
    }
    .pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
        border-radius: unset;
    }
/* end PAGINATION */

/* COOKIES */
    .containerCookie {
        bottom: 0;
        left: 0;
        position: fixed;
        right: 0;
        z-index: 3;
        background: var(--blue-light) !important;
        justify-content: center !important;
    }

    .messageCookie {
        margin: 0 0 10px;
        padding: 10px 20px;
        display: block;
        line-height: 1.2em;
        font-size: 0.85rem;
        text-align: justify;
        margin: 0;
        text-decoration: none;
        color: var(--white);
    }

    .messageCookie p {
        margin: 0;
    }

    .messageCookie a {
        color: var(--blue-dark);
        text-decoration: none;
    }
    .messageCookie a:hover {
        text-decoration: underline;
    }

    .btn-cookies {
        background-color: var(--blue-dark) !important;
        font-weight: 700;
        border: 1px solid var(--blue-dark)  !important;
        color: var(--white) !important;
        border-radius: 30px !important;
    }

    .btn-cookies:hover, .btn-cookies:active, .btn-cookies.active, .open > .dropdown-toggle.btn-cookies,
    .btn-cookies:focus, .btn-cookies.focus{
        background-color: var(--orange)  !important;
        border-color: var(--orange)  !important;
        outline: none !important;
        color: var(--white) !important;
    }
/*end COOKIES */

    #programas .card {
        border: 1px solid rgba(0,0,0,.05);
        cursor: default;
    }
    #parcerias .card,
    #gallery-cards .card,
    #gallery .card {
        /*transition: .5s;
        border: unset;
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
        cursor: default;
        border-radius: 0;*/

        border: 1px solid rgba(0,0,0,.05);
        box-shadow: 0 1px 2px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.08);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        cursor: default;
    }
    #parcerias .card:hover,
    #gallery-cards .card:hover,
    #gallery .card:hover {
        /*box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);*/
        box-shadow: 0 3px 6px rgba(0,0,0,0.06), 0 3px 6px rgba(0,0,0,0.08);
    }

/* MODAL */
    /*.modal{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }*/
    .modal .close {
        /*font-size: 2rem;
		color: var(--gray-dark) !important;*/
        -webkit-transition: all 500ms linear;
		-ms-transition: all 500ms linear;
		transition: all 500ms linear;
    }

    .modal .close:hover,
    .modal .close:focus {
        color: var(--blue-light) !important;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        transform: rotate(90deg);
    }
/* end MODAL */

/* PAGE 404 */

#notfound {
    position: relative;
    height: 100vh;
}

#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.notfound {
    max-width: 850px;
    width: 100%;
    line-height: 1.4;
    text-align: center;
    padding: 15px;
}

.notfound .notfound-404 {
    position: relative;
    height: 220px;
}

.notfound .notfound-404 h1 {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 186px;
    font-weight: 400;
    margin: 0px;
    background: linear-gradient(130deg, #ffa34f, #ff6f68);
    color:transparent;
    -webkit-background-clip: text;
    background-clip: text;
    text-transform: uppercase;
}

.notfound h2 {
    font-size: 33px;
    text-transform: uppercase;
    margin-top: 0px;
    margin-bottom: 25px;
    letter-spacing: 3px;
}


.notfound p {
    margin-top: 0px;
    margin-bottom: 25px;
}


.notfound a {
    color: #ff6f68;
    text-decoration: none;
    border-bottom: 1px dashed #ff6f68;
    border-radius: 2px;
}

.notfound-social>a {
    display: inline-block;
    height: 40px;
    line-height: 40px;
    width: 40px;
    font-size: 14px;
    color: #ff6f68;
    border: 1px solid #efefef;
    border-radius: 50%;
    margin: 3px;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
}
.notfound-social>a:hover {
    color: #fff;
    background-color: #ff6f68;
    border-color: #ff6f68;
}

/* END PAGE 404 */

/*Scroll-top bottom*/
    .scroll-top-wrapper {
        position: fixed;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        text-align: center;
        z-index: 998;
        color: rgb(255, 255, 255);
        background-color: var(--blue-light);
        border: 1px solid var(--blue-light);
        width: 50px;
        height: 50px;
        line-height: 50px;
        right: 0;
        bottom: 0;

        /* add a translate effect */
        transform: translateY(100px);

        /* add a transition */
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
    }
    .scroll-top-wrapper:hover {
        color: var(--blue-light);
        background-color: white;
        border: 1px solid var(--blue-light);
    }
    .scroll-top-wrapper.show {
        visibility: visible;
        cursor: pointer;
        opacity: 1.0;
        transform: translateY(0);
    }
    /*.scroll-top-wrapper i.fa {
        line-height: inherit;
    }*/
    /*.fa-2x {
        font-size: 1.5em;
    }*/
/*end scroll-top*/

@media only screen and (max-width: 480px) {
    .notfound .notfound-404 {
        position: relative;
        height: 168px;
    }

    .notfound .notfound-404 h1 {
        font-size: 142px;
    }

    .notfound h2 {
        font-size: 22px;
    }
}


@media screen and (min-width: 1600px) {
    .vertical-title-left {
        display: block;
        font-family: 'Outfit', sans-serif;
        font-weight: 700;
        color: rgba(131,161,178,0.3);
        opacity: 0.5;
        font-size: 80px;
        line-height: 60px;
        white-space: nowrap;
        position: absolute;
        top: 10%;
        right: 115%;
        transform-origin: top right 0;
        transform: rotate( 270deg );
    }
    
    .vertical-title-right {
        display: block;
        font-family: 'Outfit', sans-serif;
        font-weight: 700;
        color: rgba(131,161,178,0.3);
        opacity: 0.5;
        font-size: 80px;
        line-height: 60px;
        white-space: nowrap;
        position: absolute;
        top: 10%;
        right: -8%;
        transform-origin: top right 0;
        transform: rotate( 270deg );
    }
}




.quantity-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .quantity-input__modifier,
  .quantity-input__screen {
    user-select: none;
    outline: none;
  }
  
  .quantity-input__modifier {
    padding: .7rem;
    width: 3rem;
    font-size: 1rem;
    
    background: #f3f3f3;
    color: #888;
    border: 0 solid #dbdbdb;
    text-align: center;
    text-shadow: 0 1px 0 rgba(#fff, .6);
    
    cursor: pointer;
  }
  
  .quantity-input__screen {
    width: 4rem;
    padding: .7rem;
    font-size: 1rem;
    
    border: 0;
    border-top: 0 solid #dbdbdb;
    border-bottom: 0 solid #dbdbdb;
    text-align: center;
  }